import classNames from "classnames";
import React from "react";
import AboutIMG from '../assets/images/about.jpg';
import { SectionProps } from "../utils/SectionProps";
const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};

const About = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    // const [videoModalActive, setVideomodalactive] = useState(false);

    // const openModal = (e) => {
    //     e.preventDefault();
    //     setVideomodalactive(true);
    // };

    // const closeModal = (e) => {
    //     e.preventDefault();
    //     setVideomodalactive(false);
    // };

    const outerClasses = classNames(
        "hero section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "hero-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    return (
        <section {...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <img src={AboutIMG} alt="travelguru about us" />
                    <h4>About us</h4>
                    <p></p>
                    <p style={{ fontSize: 16 }}>
                        We are a passionate team of five AI enthusiasts and tech lovers, with a shared vision of harnessing the power of artificial intelligence, we are dedicated to developing innovative solutions that enhances the way people interact with technology.
                        <br />
                        Our team is driven by a deep curiosity and enthusiasm for AI, constantly exploring new possibilities by pushing the boundaries. We believe that AI has the potential to revolutionize the way we work, communicate, and live our lives.
                        <br />
                        With our expertise in AI technologies, we strive to create user-friendly and intelligent products that make a positive impact on people's daily lives.

                    </p>

                    <br /><br />
                    <h4>How did it all start?</h4>
                    <p></p>
                    <p style={{ fontSize: 16 }}>
                        During the Covid-19 lockdown in the year 2019-20, the <b>“PalmPin”</b> team started a small computer training institute at a little known village called Examba in Belgaum District of Karnataka with minimal infrastructure, and today the center has trained over 180 youngsters with computer basics and few of them are now learning programming languages to make simple mobile apps.
                        <br /><br />
                        To sustain the ecosystem and to engage the local village economy they developed a mobile app called <b>"PalmPin"</b> that connects the local farmer with the villagers for retail selling. The buyers can see the farmer’s shop & the items sold, on the app and can place orders through the App. The material may either be delivered by the farmer or can be collected by the buyer or the local youth would deliver them for a small fee. It’s very encouraging to see the local youth are able to earn ₹150-200 per day through delivery services and the farmers were able to improve their retail sales and the local buyer found it easy to shop. The entire village started liking the app and felt empowered despite having a few limitations.

                    </p>

                    <br />
                    <h4>Vision</h4>
                    <p style={{ fontSize: 16 }}>
                        To be the global leader in <b>AI powered E-Commerce</b> technology, driving innovation and setting industry standards. We envision revolutionizing digital shopping with cutting-edge solutions that redefine the future of online commerce worldwide.
                    </p>

                    <br />
                    <h4>Mission</h4>
                    <p style={{ fontSize: 16 }}>
                        Our mission is to bridge the digital gap, fostering sustainable growth, digital literacy, and local partnerships. Committed to inclusivity and environmental responsibility, we strive to pioneer a globally connected future where every individual can thrive.
                    </p>
                </div>
            </div>
        </section>
    );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
