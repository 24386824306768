import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Breaking The Barriers",
    paragraph:
      "The buyer can seamlessly become a seller and the seller can effortlessly become a buyer using the same app. Embrace versatility and explore endless opportunities within a single platform. Now, anybody in this world can do business on  “PalmPin” App  supports 15+ Local & International languages.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Experience the location based nearby busienss and events
                </div> */}
                <h3 className="mt-0 mb-12">Product showcase</h3>
                <p className="m-0">
                  Easy to list your business & products with pictures, details & product description. Setup multiple shops & unlimited product listing. You can search for required products from multiple shops from various locations across the world with automated best reviewed product listing.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/myvillage/quick.png")}
                  alt="Features split 01"
                  style={{ width: 400, height: "auto" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Busienss details and products to purchase
                </div> */}
                <h3 className="mt-0 mb-12">Place order directly</h3>
                <p className="m-0">
                  Add multiple items to your cart  from multiple shops & different locations.
                  Order directly from the store, communicate with the store owner directly to discuss and finalize discounts/ payment modes & delivery methods.
                  You can track your orders  by chatting with the individual shop owner

                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/myvillage/listing.png")}
                  alt="Features split 02"
                  style={{ width: 400, height: "auto" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Take a control of your business
                </div> */}
                <h3 className="mt-0 mb-12">Simplified User-Control</h3>
                <p className="m-0">
                  Our app goes above and beyond when it comes to empowering business owners. With our comprehensive user-controlled dashboard, business owners have full control over their products, orders and offerings. You can also create social/business events and invite your network.  Adding new products and updating existing ones is a breeze with our user-friendly interface.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/myvillage/dashboard.png")}
                  alt="Features split 03"
                  style={{ width: 400, height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
